import docReady from './docReady.js';
import { tryResumeChat, openChat } from './helpers/chat.js';
/*
 * Trengo Chat Widget
 * The chat is loaded if the chat component is clicked or the above function is called to manually open chat
 * When the chat is loaded a status is set in sessionStorage to automatically load/open the chat based on the previous state
 * if the user changes page
 */
docReady(() => {
    tryResumeChat();
    const chatComponents = document.querySelectorAll('[chat-component-hook]');
    chatComponents.forEach((chatComponent) => {
        chatComponent.addEventListener('click', (e) => {
            e.preventDefault();
            openChat(chatComponent);
        });
    });
});
